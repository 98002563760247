.App {
    text-align: center;
}

.App-logo2 {
    height: 50pt;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo2 {
        animation: App-logo2-spin infinite 30s linear;
    }
}


@keyframes App-logo2-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.normaltext{
    font-size: 40vw;
}

