
a:link {
    color: #76B7B2;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #76B7B2;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: rgba(118,183,178,0.5);
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    color: #E3FDEE;
    background-color: transparent;
    text-decoration: underline;
}
